import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(4),
  },
}));

const EmailActionsDialog = ({
  dkvUser,
  onClose,
  onForgotPassword,
  onForgotAccount,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog onClose={onClose} open={dkvUser} maxWidth={'sm'} fullWidth>
      <DialogTitle>{dkvUser && dkvUser.email}</DialogTitle>
      <DialogContent>
        <div className={classes.dialogContent}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="flex-start"
            spacing={4}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onForgotPassword(dkvUser)}
              >
                {t('send reset password email')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onForgotAccount(dkvUser)}
              >
                {t('send username recovery email')}
              </Button>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailActionsDialog;
