import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { closeNotification } from './notifications';

const Notifier = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { notifications } = useSelector((state) => state.notifications);
  const [displayedKeys, setDisplayedKeys] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    // close displayed notifications not in notifications
    const notificationsToClose = displayedKeys.filter(
      (k) => !notifications.map((n) => n.key).includes(k),
    );
    notificationsToClose.forEach((key) => {
      closeSnackbar(key);
    });

    notifications.forEach(({ key, message, options = {} }) => {
      // Do nothing if snackbar is already displayed
      if (displayedKeys.includes(key)) return;

      // Display snackbar using notistack
      enqueueSnackbar(message, {
        ...options,
        action: (key) => (
          <Button color="inherit" onClick={() => closeSnackbar(key)}>
            {t('Dismiss')}
          </Button>
        ),
        onClose: (event, reason, key) => {
          if (options.onClose) {
            options.onClose(event, reason, key);
          }
          // Dispatch action to remove snackbar from redux store
          dispatch(closeNotification(key));
        },
      });
      // Keep track of snackbars that we've displayed
      setDisplayedKeys([...displayedKeys, key]);
    });
  }, [
    closeSnackbar,
    dispatch,
    displayedKeys,
    enqueueSnackbar,
    notifications,
    t,
  ]);

  return null;
};

export default Notifier;
