import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  makeStyles,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ConfirmDeletionDialog = ({ dkvUser, onClose, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={dkvUser} onClose={onClose} maxWidth={'sm'} fullWidth>
      <DialogTitle>{dkvUser && dkvUser.email}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('Confirm deletion ?')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          {t('Cancel')}
        </Button>
        <Button onClick={() => onConfirm(dkvUser)} color="primary">
          {t('Delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeletionDialog;
