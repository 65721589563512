import React, { useState, useEffect } from 'react';
import Navigation from 'features/menu/Navigation';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Grid,
  TextField,
  Button,
  makeStyles,
  Typography,
  Paper,
} from '@material-ui/core';
import { getStatusMessage, postStatusMessage, deleteStatusMessage } from 'api';
import { displayNotification } from 'components/notification/notifications';
import { useDispatch, useSelector } from 'react-redux';
import Progress from 'components/Progress';

const titleMaxLength = 35;
const textMaxLength = 150;
const rowsMax = 4;

const useStyles = makeStyles((theme) => ({
  titleInput: {
    width: `${titleMaxLength}ch`,
  },
  textInput: {
    width: `${textMaxLength / rowsMax}ch`,
  },
  paper: {
    padding: theme.spacing(4),
  },
}));

const Status = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.session.data);

  const [loading, setLoading] = useState(false);
  const [titleFr, setTitleFr] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [titleNl, setTitleNl] = useState('');
  const [titleDe, setTitleDe] = useState('');
  const [textFr, setTextFr] = useState('');
  const [textEn, setTextEn] = useState('');
  const [textNl, setTextNl] = useState('');
  const [textDe, setTextDe] = useState('');

  const [statusMessage, setStatusMessage] = useState();

  useEffect(() => {
    const fetchStatusMessage = async () => {
      try {
        setLoading(true);
        const response = await getStatusMessage();
        // api return an empty {} when no status message
        setStatusMessage(response.titleFr ? response : null);
        setTitleFr(response.titleFr);
        setTitleEn(response.titleEn);
        setTitleNl(response.titleNl);
        setTitleDe(response.titleDe);
        setTextFr(response.textFr);
        setTextEn(response.textEn);
        setTextNl(response.textNl);
        setTextDe(response.textDe);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        dispatch(
          displayNotification({
            message: err.message,
            options: {
              variant: 'error',
            },
          }),
        );
      }
    };
    fetchStatusMessage();
  }, [dispatch]);

  const handleDelete = async (event) => {
    if (event) {
      event.preventDefault();
    }

    try {
      await deleteStatusMessage(token);
      setStatusMessage(null);
      setTitleFr('');
      setTitleEn('');
      setTitleNl('');
      setTitleDe('');
      setTextFr('');
      setTextEn('');
      setTextNl('');
      setTextDe('');

      dispatch(
        displayNotification({
          message: t('Status message deleted'),
          options: {
            variant: 'success',
          },
        }),
      );
    } catch (err) {
      dispatch(
        displayNotification({
          message: err.message,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  };

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    try {
      const data = {
        titleFr,
        titleEn,
        titleNl,
        titleDe,
        textFr,
        textEn,
        textNl,
        textDe,
      };
      await postStatusMessage(token, data);
      setStatusMessage(data);

      dispatch(
        displayNotification({
          message: t('Status message updated'),
          options: {
            variant: 'success',
          },
        }),
      );
    } catch (err) {
      dispatch(
        displayNotification({
          message: err.message,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  };

  const isSaveActive =
    titleEn &&
    titleFr &&
    titleNl &&
    textEn &&
    textNl &&
    textFr &&
    titleDe &&
    textDe;

  const isDeleteActive = statusMessage;

  return (
    <Navigation title={t('Status Message')} progress={loading}>
      <Container component="main" maxWidth="lg">
        {loading ? (
          <Progress />
        ) : (
          <Paper className={classes.paper}>
            <form
              className={classes.root}
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Grid container spacing={4}>
                <Grid
                  container
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item>
                    <Typography variant="subtitle1" color="textSecondary">
                      {t('EN')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      id="title-en"
                      className={classes.titleInput}
                      label={t('Title EN')}
                      variant="outlined"
                      value={titleEn}
                      onChange={(e) => setTitleEn(e.target.value)}
                      required
                      inputProps={{ maxLength: titleMaxLength }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      className={classes.textInput}
                      id="text-en"
                      label={t('Text EN')}
                      multiline
                      rows={rowsMax}
                      rowsMax={rowsMax}
                      variant="outlined"
                      value={textEn}
                      onChange={(e) => setTextEn(e.target.value)}
                      required
                      inputProps={{ maxLength: textMaxLength }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item>
                    <Typography variant="subtitle1" color="textSecondary">
                      {t('NL')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      className={classes.titleInput}
                      id="title-nl"
                      label={t('Title NL')}
                      variant="outlined"
                      value={titleNl}
                      onChange={(e) => setTitleNl(e.target.value)}
                      required
                      inputProps={{ maxLength: titleMaxLength }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      className={classes.textInput}
                      id="text-nl"
                      label={t('Text NL')}
                      multiline
                      rowsMax={rowsMax}
                      rows={rowsMax}
                      variant="outlined"
                      value={textNl}
                      onChange={(e) => setTextNl(e.target.value)}
                      required
                      inputProps={{ maxLength: textMaxLength }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item>
                    <Typography variant="subtitle1" color="textSecondary">
                      {t('FR')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      className={classes.titleInput}
                      id="title-fr"
                      label={t('Title FR')}
                      variant="outlined"
                      value={titleFr}
                      onChange={(e) => setTitleFr(e.target.value)}
                      required
                      inputProps={{ maxLength: titleMaxLength }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      className={classes.textInput}
                      id="text-fr"
                      label={t('Text FR')}
                      multiline
                      rows={rowsMax}
                      rowsMax={rowsMax}
                      variant="outlined"
                      value={textFr}
                      onChange={(e) => setTextFr(e.target.value)}
                      required
                      inputProps={{ maxLength: textMaxLength }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item>
                    <Typography variant="subtitle1" color="textSecondary">
                      {t('DE')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      className={classes.titleInput}
                      id="title-de"
                      label={t('Title DE')}
                      variant="outlined"
                      value={titleDe}
                      onChange={(e) => setTitleDe(e.target.value)}
                      required
                      inputProps={{ maxLength: titleMaxLength }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      className={classes.textInput}
                      id="text-de"
                      label={t('Text DE')}
                      multiline
                      rows={rowsMax}
                      rowsMax={rowsMax}
                      variant="outlined"
                      value={textDe}
                      onChange={(e) => setTextDe(e.target.value)}
                      required
                      inputProps={{ maxLength: textMaxLength }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} align="end">
                  <Grid container spacing={4}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!isDeleteActive}
                        onClick={handleDelete}
                      >
                        {t('Delete')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={!isSaveActive}
                      >
                        {t('Update')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Paper>
        )}
      </Container>
    </Navigation>
  );
};

export default Status;
