import { Grid, Hidden, LinearProgress } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import menu from '.';
import packageJson from '../../../package.json';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContent: {
    marginTop: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const CollisionLink = (path) =>
  React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} to={path} {...props} />
  ));

export default ({ title, toolbarChildren, children, progress = false }) => {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const { t } = useTranslation();

  const [menuList, setMenuList] = useState(menu(t));

  useEffect(() => {
    setMenuList(menu(t));
  }, [t]);

  const isRouteActive = (routeName) => {
    return location.pathname.indexOf(routeName) > -1;
  };

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const renderList = () => {
    return menuList.map((list, index) => (
      <div key={index}>
        <List>
          {list.items.map((item) =>
            item.path.startsWith('/') ? (
              <ListItem
                button
                key={item.title}
                selected={isRouteActive(item.path)}
                component={CollisionLink(item.path)}
              >
                <ListItemIcon>
                  <item.icon />
                </ListItemIcon>
                <ListItemText primary={t(item.title)} />
              </ListItem>
            ) : (
              <ListItem
                button
                key={item.title}
                component="a"
                href={t(item.path)}
                target="blank"
              >
                <ListItemIcon>
                  <item.icon />
                </ListItemIcon>
                <ListItemText primary={t(item.title)} />
              </ListItem>
            ),
          )}
        </List>
        {menuList.length - 1 !== index && <Divider />}
      </div>
    ));
  };

  const drawer = (
    <Grid
      className={classes.drawerContent}
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
    >
      <Grid item>
        <img src="logo.png" alt="" style={{ width: '80px', height: '80px' }} />
      </Grid>
      <Grid item>
        <Typography variant="h5" color="primary">
          {t('DKV Manager')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption" color="textSecondary">
          {packageJson.version}
        </Typography>
      </Grid>
      <Grid item>{renderList()}</Grid>
    </Grid>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar color="secondary" position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {title}
          </Typography>
          {toolbarChildren}
        </Toolbar>
        {progress && <LinearProgress />}
      </AppBar>
      <nav className={classes.drawer} aria-label="drawer">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};
