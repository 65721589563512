import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    fallbackNS: 'translation',
    defaultNS: 'translation',
    // wait until needed namespaces are loaded
    react: {
      wait: true,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    debug: false,
    detection: {
      lookupQuerystring: 'lang',
    },
    whitelist: ['en'],
  });

export default i18n;
