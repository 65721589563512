import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { login } from 'api';

export const signInUser = createAsyncThunk(
  'sign-in',
  async ({ email, password, mfaCode, token, pinCode }) => {
    const data = await login(email, password, mfaCode, token, pinCode);
    return data;
  },
);

const slice = createSlice({
  name: 'session',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    clear: (state) => {
      state.loading = false;
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: {
    [signInUser.pending]: (state) => {
      state.loading = true;
    },
    [signInUser.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.loading = false;
    },
    [signInUser.rejected]: (state, action) => {
      state.data = null;
      state.error = action.error;
      state.loading = false;
    },
  },
});

export const { clear } = slice.actions;

export default slice.reducer;
