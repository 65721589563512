import React from 'react';
import routes from 'routes/routes';

const { Redirect } = require('react-router-dom');

const Index = () => {
  return <Redirect to={routes.STATUS} />;
};

export default Index;
