import React, { useEffect, useState } from 'react';
import {
  Typography,
  makeStyles,
  TextField,
  Grid,
  Button,
  Container,
  Paper,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Progress from 'components/Progress';
import { useDispatch, useSelector } from 'react-redux';
import { displayNotification } from 'components/notification/notifications';
import { signInUser } from 'features/session';
import { unwrapResult } from '@reduxjs/toolkit';
import Unauthorized from 'api/Unauthorized';
import MfaRequired from 'api/MfaRequired';
import WrongMfaCode from 'api/WrongMfaCode';
import { postLoginLink } from 'api';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: 80,
    marginBottom: theme.spacing(4),
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');

  const session = useSelector((state) => state.session.data);

  const [loading, setLoading] = useState(false);
  const [emailCredentials, setEmailCredentials] = useState('');
  const [password, setPassword] = useState('');
  const [mfaCode, setMfaCode] = useState('');
  const [emailLogin, setEmailLogin] = useState('');
  const [pinCode, setPinCode] = useState('');

  const handleSubmitCredentials = async (event) => {
    if (event) {
      event.preventDefault();
    }

    try {
      setLoading(true);
      const result = await dispatch(
        signInUser({ email: emailCredentials, password, mfaCode }),
      );
      unwrapResult(result);
      setLoading(false);
      history.replace('/');
    } catch (err) {
      setLoading(false);

      const message = ((error) => {
        switch (error.name) {
          case Unauthorized.name:
            return t('Credentials error');
          case MfaRequired.name:
            return t('2FA required');
          case WrongMfaCode.name:
            return t('Wrong 2FA code');
          default:
            return error.message;
        }
      })(err);

      dispatch(
        displayNotification({
          message,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  };

  const handleSubmitEmail = async (event) => {
    if (event) {
      event.preventDefault();
    }

    try {
      setLoading(true);

      await postLoginLink(emailLogin);
      setLoading(false);
      dispatch(
        displayNotification({
          message: t('Check your email for the login link'),
          options: {
            variant: 'success',
          },
        }),
      );
    } catch (err) {
      setLoading(false);

      dispatch(
        displayNotification({
          message: err.message,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  };

  const handleSubmitPinCode = async (event) => {
    if (event) {
      event.preventDefault();
    }

    try {
      setLoading(true);
      const result = await dispatch(signInUser({ token, pinCode }));
      unwrapResult(result);
      setLoading(false);
      history.replace('/');
    } catch (err) {
      setLoading(false);
      const message = ((error) => {
        switch (error.name) {
          case Unauthorized.name:
            return t('Forbidden');
          default:
            return error.message;
        }
      })(err);

      dispatch(
        displayNotification({
          message,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  };

  const isCredentialsInvalid = password === '' || emailCredentials === '';
  const isEmailLoginInvalid = emailLogin === '';

  return (
    <Container className={classes.root} component="main" maxWidth="xs">
      <Grid
        style={{ minHeight: '90vh' }}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        {loading ? (
          <Progress />
        ) : (
          <Paper className={classes.paper}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={4}
            >
              <img className={classes.logo} src="logo.png" />

              {token ? (
                <Grid item>
                  <form onSubmit={handleSubmitPinCode}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      value={pinCode}
                      required
                      fullWidth
                      label={t('Pin code')}
                      autoFocus
                      onChange={(e) => setPinCode(e.target.value)}
                    />
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      {t('Login')}
                    </Button>
                  </form>
                </Grid>
              ) : (
                <>
                  {' '}
                  <Grid item>
                    <form onSubmit={handleSubmitCredentials}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        value={emailCredentials}
                        required
                        fullWidth
                        label={t('Email address')}
                        autoComplete="email"
                        autoFocus
                        onChange={(e) => setEmailCredentials(e.target.value)}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        value={password}
                        fullWidth
                        label={t('Password *')}
                        type="password"
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        value={mfaCode}
                        fullWidth
                        label={t('2FA Code')}
                        onChange={(e) => setMfaCode(e.target.value)}
                      />
                      <Button
                        fullWidth
                        disabled={isCredentialsInvalid}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        {t('Login')}
                      </Button>
                    </form>
                  </Grid>
                  <Grid item>
                    <form onSubmit={handleSubmitEmail}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        value={emailLogin}
                        required
                        fullWidth
                        label={t('Email address')}
                        autoComplete="email"
                        autoFocus
                        onChange={(e) => setEmailLogin(e.target.value)}
                      />
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isEmailLoginInvalid}
                        className={classes.submit}
                      >
                        {t('Email me a login link')}
                      </Button>
                    </form>
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
        )}
      </Grid>
    </Container>
  );
};
