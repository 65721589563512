import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import routes from 'routes/routes';
import Navigation from 'features/menu/Navigation';
import { clear } from 'features/session';
import { useDispatch } from 'react-redux';
import ConfigureMfaDialog from './ConfigureMfaDialog';

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(4),
  },
}));

export default () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [configureMfa, setConfigureMfa] = useState(false);

  const handleLogout = () => {
    dispatch(clear());
    history.replace(routes.SIGN_IN);
  };

  return (
    <Navigation title={t('Settings')}>
      <Container component="main" maxWidth="sm">
        <Paper className={classes.paper}>
          <Grid
            className={classes.root}
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
            spacing={4}
          >
            <Grid item>
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
                spacing={4}
              >
                <Grid item>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    fullWidth
                  >
                    <InputLabel id="language">{t('Language')}</InputLabel>
                    <Select
                      labelId="language"
                      id="language"
                      label={t('Language')}
                      value={i18n.language}
                      onChange={(e) => {
                        i18n.changeLanguage(e.target.value);
                      }}
                    >
                      {i18n.languages.map((language) => (
                        <MenuItem value={language}>{language}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setConfigureMfa(true);
                    }}
                  >
                    {t('Configure 2FA')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleLogout}
                  >
                    {t('Logout')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <ConfigureMfaDialog
        open={configureMfa}
        onClose={() => setConfigureMfa(false)}
      />
    </Navigation>
  );
};
