import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getMfa, postMfaConfirm } from 'api';
import { displayNotification } from 'components/notification/notifications';
import QrCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(4),
  },
}));

const ConfigureMfaDialog = ({ open, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { token } = useSelector((state) => state.session.data);

  const [mfaCode, setMfaCode] = useState();

  const [uri, setUri] = useState();

  useEffect(() => {
    const generateMfa = async () => {
      try {
        const response = await getMfa(token);
        setUri(response.uri);
      } catch (err) {
        dispatch(
          displayNotification({
            message: err.message,
            options: {
              variant: 'error',
            },
          }),
        );
      }
    };
    if (open) {
      generateMfa();
    }
  }, [dispatch, open, token]);

  const handleMfaSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    try {
      setLoading(true);
      await postMfaConfirm(token, mfaCode);
      setLoading(false);
      dispatch(
        displayNotification({
          message: t('2FA activated'),
          options: {
            variant: 'success',
          },
        }),
      );
      onClose();
    } catch (err) {
      dispatch(
        displayNotification({
          message: err.message,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={'sm'}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle>{t('Configure 2FA')}</DialogTitle>
      <DialogContent>
        <div className={classes.dialogContent}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item>{uri && <QrCode value={uri} />}</Grid>
            <Grid item>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label={t('Code')}
                autoFocus
                onChange={(e) => setMfaCode(e.target.value)}
              />
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('Disable 2FA')}</Button>
        <Button autoFocus onClick={handleMfaSubmit} color="primary">
          {t('Enable 2FA')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfigureMfaDialog;
