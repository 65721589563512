import superagent from 'superagent';
import config from 'config';
import get from 'lodash.get';
import { clear } from 'features/session';
import { store } from 'store';
import Unauthorized from './Unauthorized';
import MfaRequired from './MfaRequired';
import WrongMfaCode from './WrongMfaCode';

const endpoint = config.apiEndpoint;

export const login = async (email, password, mfaCode, token, pinCode) => {
  try {
    const res = await superagent
      .post(`${endpoint}/admin/login`)
      .send({ email, password, mfaCode, token, pinCode });
    return res.body;
  } catch (e) {
    // server returns Forbidden for wrong credentials ?
    if (e.status === 403) {
      store.dispatch(clear());
      throw new Unauthorized(e.message);
    }
    if (
      e.status === 401 &&
      get(e, 'response.body.error') === 'error.mfaRequired'
    ) {
      throw new MfaRequired(e.message);
    }
    if (
      e.status === 401 &&
      get(e, 'response.body.error') === 'error.wrongMfa'
    ) {
      throw new WrongMfaCode(e.message);
    }
    throw e;
  }
};

export const getMfa = async (token) => {
  try {
    const res = await superagent
      .get(`${endpoint}/admin/generateMfa`)
      .auth(token, { type: 'bearer' });

    return res.body;
  } catch (e) {
    // server returns Forbidden for wrong credentials ?
    if (e.status === 403) {
      store.dispatch(clear());
      throw new Unauthorized(e.message);
    }
    throw e;
  }
};

export const postMfaConfirm = async (token, code) => {
  try {
    const res = await superagent
      .post(`${endpoint}/admin/confirmMfa`)
      .auth(token, { type: 'bearer' })
      .send({ code });

    return res.body;
  } catch (e) {
    // server returns Forbidden for wrong credentials ?
    if (e.status === 403) {
      store.dispatch(clear());
      throw new Unauthorized(e.message);
    }
    throw e;
  }
};

export const getUsers = async (token, searchField, value) => {
  try {
    const res = await superagent
      .get(`${endpoint}/admin/dkvuser`)
      .auth(token, { type: 'bearer' })
      .query({ [searchField]: value });
    return res.body.users;
  } catch (e) {
    // server returns Forbidden for wrong credentials ?
    if (e.status === 403) {
      store.dispatch(clear());
      throw new Unauthorized(e.message);
    }
    throw e;
  }
};

export const deleteUser = async (token, dkvUserId) => {
  try {
    const res = await superagent
      .delete(`${endpoint}/admin/dkvuser/${dkvUserId}`)
      .auth(token, { type: 'bearer' });

    return res.body;
  } catch (e) {
    // server returns Forbidden for wrong credentials ?
    if (e.status === 403) {
      store.dispatch(clear());
      throw new Unauthorized(e.message);
    }
    throw e;
  }
};

export const postForgotPassword = async (token, dkvUserId) => {
  try {
    const res = await superagent
      .post(`${endpoint}/admin/dkvuser/${dkvUserId}/forgotPassword`)
      .auth(token, { type: 'bearer' });

    return res.body;
  } catch (e) {
    // server returns Forbidden for wrong credentials ?
    if (e.status === 403) {
      store.dispatch(clear());
      throw new Unauthorized(e.message);
    }
    throw e;
  }
};

export const postForgotAccount = async (token, dkvUserId) => {
  try {
    const res = await superagent
      .post(`${endpoint}/admin/dkvuser/${dkvUserId}/forgotAccount`)
      .auth(token, { type: 'bearer' });

    return res.body;
  } catch (e) {
    // server returns Forbidden for wrong credentials ?
    if (e.status === 403) {
      store.dispatch(clear());
      throw new Unauthorized(e.message);
    }
    throw e;
  }
};

export const getStatusMessage = async (token) => {
  const res = await superagent.get(`${endpoint}/statusMessage`);
  return res.body;
};

export const postStatusMessage = async (
  token,
  { titleEn, titleNl, titleFr, textEn, textNl, textFr, titleDe, textDe },
) => {
  try {
    const res = await superagent
      .post(`${endpoint}/admin/statusMessage`)
      .auth(token, { type: 'bearer' })
      .send({
        titleEn,
        titleNl,
        titleFr,
        textEn,
        textNl,
        textFr,
        titleDe,
        textDe,
      });
    return res.body;
  } catch (e) {
    // server returns Forbidden for wrong credentials ?
    if (e.status === 403) {
      store.dispatch(clear());
      throw new Unauthorized(e.message);
    }
    throw e;
  }
};

export const deleteStatusMessage = async (token) => {
  try {
    const res = await superagent
      .delete(`${endpoint}/admin/statusMessage`)
      .auth(token, { type: 'bearer' });

    return res.body;
  } catch (e) {
    // server returns Forbidden for wrong credentials ?
    if (e.status === 403) {
      store.dispatch(clear());
      throw new Unauthorized(e.message);
    }
    throw e;
  }
};

export const postLoginLink = async (email) => {
  const res = await superagent
    .post(`${endpoint}/admin/sendLoginLink`)
    .send({ email });

  return res.body;
};
