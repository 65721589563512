import NotFound from 'components/NotFound';
import Progress from 'components/Progress';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Index from 'features/Index';
import Status from 'features/status/Status';
import SignIn from 'features/signin/SignIn';
import { useSelector } from 'react-redux';
import Settings from 'features/settings/Settings';
import Users from 'features/users/Users';
import { jwtDecode } from 'jwt-decode';
import { store } from 'store';
import { clear } from 'features/session';
import routes from './routes';

const isTokenStillValid = (jwtToken, seconds = 0) => {
  const decodedToken = jwtDecode(jwtToken);
  console.log(decodedToken);
  const currentDate = new Date();
  const timeBeforeExpiration = decodedToken.exp * 1000 - currentDate.getTime();
  return timeBeforeExpiration > seconds * 1000;
};

const ProtectedRoute = ({ session, ...props }) => {
  if (session.loading) {
    return <Progress />;
  }
  if (session.data) {
    // token is valid only 2min ??? so 0 for now
    if (isTokenStillValid(session.data.token, 0)) {
      return <Route {...props} />;
    }
    console.log('Token expired');
    store.dispatch(clear());
    return null;
  }

  return <Redirect to={routes.SIGN_IN} />;
};

const Routes = () => {
  const session = useSelector((state) => state.session);

  return (
    <Switch>
      <Route exact path="/" component={Index} />
      <Route exact path={routes.SIGN_IN} component={SignIn} />

      <ProtectedRoute
        exact
        path={routes.STATUS}
        session={session}
        component={Status}
      />
      <ProtectedRoute
        exact
        path={routes.SETTINGS}
        session={session}
        component={Settings}
      />
      <ProtectedRoute
        exact
        path={routes.USERS}
        session={session}
        component={Users}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
