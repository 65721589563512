import {
  Box,
  Collapse,
  Container,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EmailIcon from '@material-ui/icons/Email';
import SearchIcon from '@material-ui/icons/Search';
import {
  deleteUser,
  getUsers,
  postForgotAccount,
  postForgotPassword,
} from 'api';
import { displayNotification } from 'components/notification/notifications';
import Navigation from 'features/menu/Navigation';
import MaterialTable from 'material-table';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ConfirmDeletionDialog from './ConfirmDeletionDialog';
import EmailActionsDialog from './EmailActionsDialog';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
  selectSearchField: {
    minWidth: 140,
  },
  row: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
}));

const searchFields = ['userName', 'email', 'id', 'medicardCode'];

const Users = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.session.data);

  const [progress, setProgress] = useState(false);
  const [users, setUsers] = useState([]);

  const [searchField, setSearchField] = useState(searchFields[0]);
  const [searchQuery, setSearchQuery] = useState('');

  const [emailActionsDialogUser, setEmailActionsDialogUser] = useState(null);
  const [confirmDeletionDialogUser, setConfirmDeletionDialogUser] = useState(
    null,
  );

  const handleSearch = async (event) => {
    if (event) {
      event.preventDefault();
    }

    try {
      setProgress(true);
      const data = await getUsers(token, searchField, searchQuery);
      setUsers(data);
      setProgress(false);
    } catch (err) {
      setProgress(false);
      dispatch(
        displayNotification({
          message: err.message,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  };

  const handleUserDeletion = async (dkvUser) => {
    try {
      setProgress(true);
      const response = await deleteUser(token, dkvUser.id);
      setProgress(false);
      dispatch(
        displayNotification({
          message: t('User deleted'),
          options: {
            variant: 'success',
          },
        }),
      );
    } catch (err) {
      setProgress(false);
      dispatch(
        displayNotification({
          message: err.message,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  };

  const handleUserForgotAccount = async (dkvUser) => {
    try {
      setProgress(true);
      const response = await postForgotAccount(token, dkvUser.id);
      setProgress(false);
      dispatch(
        displayNotification({
          message: t('Forgot account email sent'),
          options: {
            variant: 'success',
          },
        }),
      );
    } catch (err) {
      setProgress(false);
      dispatch(
        displayNotification({
          message: err.message,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  };

  const handleUserForgotPassword = async (dkvUser) => {
    try {
      setProgress(true);
      const response = await postForgotPassword(token, dkvUser.id);
      setProgress(false);
      dispatch(
        displayNotification({
          message: t('Forgot password email sent'),
          options: {
            variant: 'success',
          },
        }),
      );
    } catch (err) {
      setProgress(false);
      dispatch(
        displayNotification({
          message: err.message,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  };

  function UserRow({ row: user }) {
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow className={classes.row}>
          <TableCell>
            <IconButton onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {user.id}
          </TableCell>
          <TableCell align="right">{user.userName}</TableCell>
          <TableCell align="right">{user.email}</TableCell>
          <TableCell align="right">{user.firstName}</TableCell>
          <TableCell align="right">{user.lastName}</TableCell>
          <TableCell align="right">{user.dateOfBirth}</TableCell>
          <TableCell align="right">
            <IconButton onClick={() => setEmailActionsDialogUser(user)}>
              <EmailIcon />
            </IconButton>
            <IconButton onClick={() => setConfirmDeletionDialogUser(user)}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('code')}</TableCell>
                      <TableCell align="right">{t('mainCard')}</TableCell>
                      <TableCell align="right">{t('name')}</TableCell>
                      <TableCell align="right">{t('firstName')}</TableCell>
                      <TableCell align="right">{t('lastName')}</TableCell>
                      <TableCell align="right">{t('dateOfBirth')}</TableCell>
                      <TableCell align="right">{t('email')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {user.medicards.map((medicard) => (
                      <TableRow key={medicard.code}>
                        <TableCell component="th" scope="row">
                          {medicard.code}
                        </TableCell>
                        <TableCell align="right">
                          {medicard.mainCard ? t('yes') : t('no')}
                        </TableCell>
                        <TableCell align="right">{medicard.name}</TableCell>
                        <TableCell align="right">
                          {medicard.firstName}
                        </TableCell>
                        <TableCell align="right">{medicard.lastName}</TableCell>
                        <TableCell align="right">
                          {medicard.dateOfBirth}
                        </TableCell>
                        <TableCell align="right">{medicard.email}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <Navigation title={t('Users')} progress={progress}>
      <Container component="main" maxWidth="lg">
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <form onSubmit={handleSearch}>
              <Grid
                container
                direction="row"
                spacing={2}
                justify="flex-start"
                alignItems="flex-end"
              >
                <Grid item>
                  <Select
                    className={classes.selectSearchField}
                    value={searchField}
                    onChange={(e) => setSearchField(e.target.value)}
                  >
                    {searchFields.map((f) => (
                      <MenuItem value={f}>{f}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item>
                  <TextField
                    id="search"
                    label={t('search')}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <IconButton type="submit" size="small">
                          <SearchIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>

          <Grid item>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>{t('id')}</TableCell>
                    <TableCell align="right">{t('userName')}</TableCell>
                    <TableCell align="right">{t('email')}</TableCell>
                    <TableCell align="right">{t('firstName')}</TableCell>
                    <TableCell align="right">{t('lastName')}</TableCell>
                    <TableCell align="right">{t('dateOfBirth')}</TableCell>
                    <TableCell align="right">{t('actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users &&
                    users.map((user) => <UserRow key={user.id} row={user} />)}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <EmailActionsDialog
          dkvUser={emailActionsDialogUser}
          onClose={() => setEmailActionsDialogUser(null)}
          onForgotPassword={handleUserForgotPassword}
          onForgotAccount={handleUserForgotAccount}
        />
        <ConfirmDeletionDialog
          dkvUser={confirmDeletionDialogUser}
          onClose={() => setConfirmDeletionDialogUser(null)}
          onConfirm={handleUserDeletion}
        />
      </Container>
    </Navigation>
  );
};

export default Users;
