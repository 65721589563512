import AnnouncementIcon from '@material-ui/icons/Announcement';
import SettingsIcon from '@material-ui/icons/Settings';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import routes from 'routes/routes';

export default (t) => [
  {
    items: [
      {
        title: t('Status Message'),
        icon: AnnouncementIcon,
        path: routes.STATUS,
      },
      {
        title: t('Users'),
        icon: SupervisorAccountIcon,
        path: routes.USERS,
      },
    ],
  },
  {
    items: [
      {
        title: t('Settings'),
        icon: SettingsIcon,
        path: routes.SETTINGS,
      },
    ],
  },
];
